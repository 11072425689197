$sm-menu-width: 320px !default;
$sm-menu-bg: #333 !default;

$sm-link-color: #fff !default;
$sm-link-padding: 12px 24px !default;

$sm-transition-dur: 300ms !default;
$sm-transition-easing: ease-in-out !default;


@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
}

.slide-menu {
	position: fixed;
	width: $sm-menu-width;
	max-width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
	display: none;
	overflow: hidden;
	box-sizing: border-box;
	background: $sm-menu-bg;
	transition: transform $sm-transition-dur $sm-transition-easing;
	transform: translateX(100%);
	will-change: transform;
	z-index: 1000;

	.slider {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		transition: transform $sm-transition-dur $sm-transition-easing;
		transform: translateX(0);
		will-change: transform;
	}

	ul {
		@include list-unstyled;

		position: relative;
		width: $sm-menu-width;
		max-width: 100%;
		margin: 0;

		ul {
			position: absolute;
			top: 0;
			left: 100%;
			display: none;
		}

		a {
			display: block;
			padding: $sm-link-padding;
			color: $sm-link-color;
			text-decoration: none;
		}
	}

	&.no-transition, &.no-transition * {
		transition: none !important;
	}
}